const SocialLinks = () => {
  const socialLinks = [
    {
      id: 1,
      iconClass: "fab fa-facebook-f",
      href: "https://www.facebook.com/mbrasempreendimentos",
    },
    {
      id: 2,
      iconClass: "fab fa-x-twitter",
      href: "https://www.twitter.com/mbrasimoveis",
    },
    {
      id: 3,
      iconClass: "fab fa-instagram",
      href: "https://www.instagram.com/mbrasempreendimentos/",
    },
    {
      id: 4,
      iconClass: "fab fa-linkedin-in",
      href: "https://linkedin.com/company/mbras-soluções-imobiliárias/",
    },
  ];

  return (
    <>
      {socialLinks.map((link) => (
        <a className="me-3" href={link.href} key={link.id}>
          <i className={link.iconClass}></i>
        </a>
      ))}
    </>
  );
};

export default SocialLinks;
