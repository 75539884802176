module.exports = [
  {
    path: "/",
    label: "Home",
  },
  {
    path: "/sobre",
    label: "Sobre",
    subMenu: [
      { path: "/sobre", label: "Sobre" },
      { path: "/gestao", label: "Gestão" },
      { path: "/club", label: "MBRAS Club" },
      {
        path: "https://www.linkedin.com/company/mbras-solu%C3%A7%C3%B5es-imobili%C3%A1rias/jobs/",
        label: "Trabalhe conosco",
      },
    ],
  },
  {
    path: "/fast",
    label: "Imóveis",
    subMenu: [
      {
        path: "/fast?type=sale",
        label: "Busca Expressa",
      },
      {
        path: "https://fast.mbras.com.br/app?initial_search_type=map",
        label: "Buscar por Mapa",
      },
    ],
  },
  {
    path: "/contato",
    label: "Contato",
  },
  // {
  //   label: "Pages",
  //   subMenu: [
  //     { path: "/about", label: "About" },
  //     { path: "/contato", label: "Contact" },
  //     { path: "/compare", label: "Compate" },
  //     { path: "/pricing", label: "Pricing" },
  //     { path: "/faq", label: "Faq" },
  //     { path: "/login", label: "Login" },
  //     { path: "/register", label: "Register" },
  //     { path: "/404", label: "404" },
  //     { path: "/invoice", label: "Invoice" },
  //   ],
  // },
];
