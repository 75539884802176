export const homeItems = [{ href: "/", label: "Home" }];

export const aboutItems = [
  { href: "/sobre", label: "Sobre" },
  { href: "/gestao", label: "Gestão" },
  { href: "/club", label: "MBRAS Club" },
  { href: "https://consultor.mbras.com.br/", label: "Trabalhe conosco" },
];

{
  /*
    title: "Grade",
    submenu: [{ label: "Busca por listagem", href: "/listing" }],
  */
}
export const listingItems = [
  {
    title: "Expressa",
    submenu: [
      {
        label: "Busca Expressa",
        href: "/fast?type=sale",
      },
    ],
  },
  {
    title: "Mapa",
    submenu: [
      {
        label: "Busca por Mapa",
        href: "https://fast.mbras.com.br/app?initial_search_type=map",
      },

      // { label: "Map V1", href: "/map-v1" },
      // { label: "Map V2", href: "/map-v2" },
      // { label: "Map V3", href: "/map-v3" },
      // { label: "Map V4", href: "/map-v4" },
    ],
  },
  // {
  //   title: "Tradicional",
  //   submenu: [
  //     {
  //       label: "Busca por Filtros ",
  //       href: "/listing",
  //     },
  //   ],
  // },
];

export const propertyItems = [
  {
    label: "Agents",
    subMenuItems: [
      { label: "Agents", href: "/agents" },
      { label: "Agent Single", href: "/agent-single/1" },
      { label: "Agency", href: "/agency" },
      { label: "Agency Single", href: "/agency-single/1" },
    ],
  },

  {
    label: "Single Style",
    subMenuItems: [{ label: "Single V8", href: "/single-v8/1" }],
  },
  {
    label: "Dashboard",
    subMenuItems: [
      { label: "Dashboard Home", href: "/dashboard-home" },
      { label: "Message", href: "/dashboard-message" },
      { label: "New Property", href: "/anuncie" },
      { label: "My Properties", href: "/dashboard-my-properties" },
      { label: "My Favorites", href: "/dashboard-my-favourites" },
      { label: "Saved Search", href: "/dashboard-saved-search" },
      { label: "Reviews", href: "/dashboard-reviews" },
      { label: "My Package", href: "/dashboard-my-package" },
      { label: "My Profile", href: "/dashboard-my-profile" },
    ],
  },
];

export const blogItems = [
  // { href: "/blog-list-v1", label: "Blog List V1" },
  // { href: "/blog-list-v2", label: "Blog List V2" },
  // { href: "/blog-list-v3", label: "Blog List V3" },
  // { href: "/blogs/2", label: "Blog Single" },
];

export const pageItems = [
  // { href: "/about", label: "About" },
  // { href: "/contato", label: "Contact" },
  // { href: "/compare", label: "Compate" },
  // { href: "/pricing", label: "Pricing" },
  // { href: "/faq", label: "Faq" },
  // { href: "/login", label: "Login" },
  // { href: "/register", label: "Register" },
  // { href: "/not-found", label: "404" },
  // { href: "/invoice", label: "Invoice" },
];
