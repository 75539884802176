import React from "react";
import { useTranslations } from "next-intl";

const ContactInfo = () => {
  const t = useTranslations("header.sidebar.footer.blocks");

  const contactInfo = [
    {
      id: 1,
      title: t("block1.title"),
      phone: "+55 11 97799 8888",
      phoneHref: "https://api.whatsapp.com/send?phone=5511977998888",
    },
    {
      id: 2,
      title: t("block2.title"),
      email: "mbras@mbras.com.br",
      emailHref: "mailto:mbras@mbras.com.br",
    },
  ];

  return (
    <>
      {contactInfo.map((info) => (
        <div className="col-auto" key={info.id}>
          <div className="contact-info">
            <p className="info-title dark-color">{info.title}</p>
            {info.phone && (
              <p className="info-phone dark-color">
                <a href={info.phoneHref}>{info.phone}</a>
              </p>
            )}
            {info.email && (
              <p className="info-mail dark-color">
                <a href={info.emailHref}>{info.email}</a>
              </p>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default ContactInfo;
