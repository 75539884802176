import { useTranslations } from "next-intl";

const MenuItems = () => {
  const t = useTranslations("header.sidebar.body");

  const menuItems = [
    { value: "apartments", label: t("links.apartments") },
    { value: "houses", label: t("links.houses") },
    {
      value: "condominium_houses",
      label: t("links.condominium_houses"),
    },
    { value: "roof", label: t("links.roof") },
    { value: "ground", label: t("links.ground") },
    { value: "commercial", label: t("links.commercial") },
  ];

  return (
    <ul className="navbar-nav">
      {menuItems.map((item) => (
        <li className="nav-item" key={item.value}>
          <a
            className="nav-link"
            href={`/fast?property_type=${item.value}`}
            role="button"
          >
            {item.label}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default MenuItems;
