"use client";
import React from "react";
import mobileMenuItems from "@/data/mobileMenuItems";
import { isParentActive } from "@/utilis/isMenuActive";
import { usePathname } from "next/navigation";
import Link from "next/link";

import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

const ProSidebarContent = () => {
  const path = usePathname();

  return (
    <Sidebar width="100%" backgroundColor="#fff" className="my-custom-class">
      <Menu>
        {mobileMenuItems.map((item, index) => (
          <React.Fragment key={index}>
            {/* <MenuItem
              key={index}
              component={
                <Link
                  className={item.path == path ? "active" : ""}
                  href={item.path}
                />
              }
            >
              {item.label}
            </MenuItem> */}
            {item.subMenu ? (
              <SubMenu
                key={item.label + index}
                className={""}
                label={item.label}
              >
                {item.subMenu.map((subItem, subIndex) =>
                  subItem.subMenu ? (
                    <SubMenu
                      key={subIndex + Math.random() * 1024}
                      label={subItem.label}
                      className={
                        isParentActive(subItem.subMenu, path) ? "active" : ""
                      }
                      href="/"
                    >
                      {subItem.subMenu.map((nestedItem, nestedIndex) => (
                        <MenuItem
                          key={nestedIndex}
                          component={
                            <Link
                              className={
                                nestedItem.path == path ? "active" : ""
                              }
                              href={nestedItem.path}
                            />
                          }
                        >
                          {nestedItem.label}
                        </MenuItem>
                      ))}
                    </SubMenu>
                  ) : (
                    <MenuItem
                      key={subIndex}
                      component={
                        <Link
                          className={subItem.path == path ? "active" : ""}
                          href={subItem.path}
                        />
                      }
                    >
                      {subItem.label}
                    </MenuItem>
                  )
                )}
              </SubMenu>
            ) : (
              <MenuItem
                key={index}
                component={
                  <Link
                    className={item.path == path ? "active" : ""}
                    href={item.path || "/"}
                  />
                }
              >
                {item.label}
              </MenuItem>
            )}
          </React.Fragment>
        ))}
      </Menu>
    </Sidebar>
  );
};

export default ProSidebarContent;
